import classNames from "classnames"

import { ArrowRight as ArrowRightIcon } from "assets/icons"
import { useCurrentUserContext } from "context/CurrentUserContext"

import Button from "../elements/Button"

export enum ESortDirections {
  ASCENDING = "asc",
  DESCENDING = "desc",
}

export type THeader = {
  id?: string
  label: string
  className?: string
  sortDirection?: ESortDirections
  onClick?: () => void
  isCheckbox?: boolean
}

type TRowBaseProps = {
  cells: Record<string, number | string | boolean | null>
  rowIndex: number
}
export type TDataRow<TRowProps> = TRowProps & { id: number | string }

type THeaderAction = {
  label: string
  onClick: (selectedIds: (string | number)[]) => void
}

type TSimpleTableProps<TRowProps> = {
  RowComponent: (props: TRowProps & TRowBaseProps) => JSX.Element
  dataRows?: Array<
    TDataRow<
      TRowProps & { cells: Record<string, number | string | boolean | null> }
    >
  >
  dataHeaders?: THeader[]
  sortBy?: string
  classNameTable?: string
  headerActions?: THeaderAction[]
  selectedIds?: (string | number)[]
  onSelectionChange?: (ids: (string | number)[]) => void
}

export default function SimpleTable<TRowProps>({
  dataRows = [],
  dataHeaders = [],
  RowComponent,
  sortBy,
  classNameTable,
  headerActions = [],
  selectedIds = [],
  onSelectionChange,
}: TSimpleTableProps<TRowProps>): JSX.Element {
  const { isDev } = useCurrentUserContext()
  const handleSelectAll = () => {
    if (onSelectionChange) {
      const allIds = dataRows.map(row => row.id)
      onSelectionChange(selectedIds.length === dataRows.length ? [] : allIds)
    }
  }

  return (
    <div className="flex flex-col gap-2">
      {isDev && headerActions.length > 0 && selectedIds.length > 0 && (
        <div className="flex gap-2">
          {headerActions.map((action, idx) => (
            <Button
              key={idx}
              onClick={() => action.onClick(selectedIds)}
            >
              {action.label}
            </Button>
          ))}
        </div>
      )}
      <div className="overflow-hidden rounded-md border border-black">
        <table
          className={classNames([
            "SimpleTable",
            "w-full",
            "table-fixed",
            "divide-y divide-black",
            classNameTable,
          ])}
        >
          <thead className="bg-yellow-200">
            <tr>
              {dataHeaders?.map(
                ({ id, label, className = "", sortDirection, onClick, isCheckbox }) => (
                  <th
                    key={label || 'checkbox'}
                    scope="col"
                    className={classNames([
                      "px-6 py-3",
                      "text-left text-sm uppercase tracking-wider text-black",
                      className,
                    ])}
                    {...(isCheckbox ? {} : { onClick })}
                  >
                    {isCheckbox ? (
                      <input
                        className="h-4 w-4 rounded border border-black bg-gray-100 checked:bg-electricBlue hover:cursor-pointer focus:ring-blue-500"
                        type="checkbox"
                        checked={selectedIds.length === dataRows.length}
                        onChange={handleSelectAll}
                      />
                    ) : (
                        <div className="flex items-center">
                          <span className={classNames([sortDirection ? "mr-1" : null])}>
                            {label}
                          </span>
                          {sortBy === id && sortDirection && (
                            <ArrowRightIcon
                              className={classNames([
                                "icon",
                                sortDirection === ESortDirections.DESCENDING
                                  ? "rotate-90"
                                  : "-rotate-90",
                              ])}
                            />
                          )}
                        </div>
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>

          <tbody className="divide-y divide-black bg-white">
            {dataRows.map((rowProps, idx) => {
              const { id } = rowProps
              return <RowComponent {...rowProps} key={id} rowIndex={idx} />
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
