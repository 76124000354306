import { Popover, Transition } from "@headlessui/react"
import { Fragment, ReactNode } from "react"

import { cn } from "utils"

import FloatPlacement from "./FloatPlacement"

type TProps = {
  buttonClassName: string
  buttonContent: ReactNode
  panelClassName?: string
  children: ReactNode
}

export default function PopoverMenu(props: TProps): JSX.Element {
  const { children, buttonContent, buttonClassName, panelClassName } = props

  return (
    <Popover className="relative">
      <FloatPlacement>
        <Popover.Button className={buttonClassName}>
          {buttonContent}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel
            className={cn(
              "absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-4 sm:px-0",
              panelClassName
            )}
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black">
              {children}
            </div>
          </Popover.Panel>
        </Transition>
      </FloatPlacement>
    </Popover>
  )
}
